export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "connectWallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect Wallet"])},
        "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["logout"])},
        "announ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NEWS"])},
        "addNECOToMetamask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to add NECO to Metamask"])}
      },
      "zh": {
        "connectWallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["链接钱包"])},
        "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["退出"])},
        "announ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公告"])},
        "addNECOToMetamask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["点击将NECO添加到小狐狸钱包"])}
      }
    }
  })
}
