class Contact {
  name: string;

  link: string;

  icon: string;

  public constructor(name: string, link: string, icon = '') {
    this.name = name;
    this.link = link;
    this.icon = icon;
  }
}
const contactList = [
  new Contact('twitter', 'https://twitter.com/fun_neco', 'icon-twitter'),
  new Contact('discord', 'https://discord.com/invite/ndx2dB2rj4', 'icon-discord'),
  new Contact('telegram', 'https://t.me/necofun', 'icon-telegram'),
  new Contact('medium', 'https://medium.com/@necofun.games', 'icon-medium'),
  new Contact('github', 'https://github.com/Neco-Fun', 'icon-github'),
  new Contact('whitepaper', 'https://docs.necofun.com/', 'icon-whitepaper'),
];

export {
  contactList,
  Contact,
};
