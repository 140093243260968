/* eslint no-underscore-dangle: ["error", { "allow": ["_trans"] }] */
class Menu {
  public name: string;

  public router: string;

  private _trans = '';

  // is menu open
  isOpen = true;

  // menu icon
  icon = 'icon-paw';

  // submenu
  children:Array<Menu> = [];

  public constructor(name: string, router: string, trans = '', icon = '', isOpen = true) {
    this.name = name;
    this.router = router;
    this.trans = trans;
    this.icon = icon;
    this.isOpen = isOpen;
  }

  set trans(val: string) {
    this._trans = val;
  }

  get trans(): string {
    return this._trans;
  }
}

const economics = new Menu('ECONOMICS', '/economics', '', 'icon-economic');
economics.children = [
  new Menu('OVERALL', '/economics', '', 'icon-paw'),
  new Menu('NECOCOIN', '/neco-token', 'necoCoin', 'icon-paw'),
  new Menu('FISHCOIN', '/n-fish', 'fishCoin', 'icon-paw'),
];

const farming = new Menu('FARMS', '/token-farming', '', 'icon-farm');
farming.children = [
  new Menu('TOKEN FARM', '/token-farming', 'tokenFarm', 'icon-paw'),
  new Menu('NFT FARM', '/nft-farm', 'nftFarm', 'icon-paw'),
];

const marketplace = new Menu('MARKETPLACE', '/nft-market', '', 'icon-ntf', false);
const NFTs = new Menu('NFTs', '/my-nft-list', 'NFTS', 'icon-my-ntf');
NFTs.children = [
  new Menu('NFTLibrary', '/nft-library', 'NFTLibrary', 'icon-paw'),
  new Menu('MYNFTS', '/my-nft-list', 'MYNFTS', 'icon-paw'),
];

const menuList = [
  new Menu('HOME', '/', '', 'icon-paw'),
  economics,
  new Menu('ROADMAP', '/roadmap', '', 'icon-location'),
  farming,
  NFTs,
  new Menu('GAMES', '/Games', '', 'icon-game', false),
  marketplace,
  // new Menu('NFT GASHAPON', '/nft-gashapon', 'nftGashapon', 'icon-gashapon', false),
];

export {
  menuList,
  Menu,
};
