/**
 * update notice:
 *
 * 1. set NOTICE_READ_PREV_KEY = NOTICE_READ_KEY
 * 2. set NOTICE_READ_KEY to a new value, for example 'NOTICE_HAVE_READ_0524'
 * */
// previous read key, for update notice
export const NOTICE_READ_PREV_KEY = 'NOTICE_HAVE_READ';

export const NOTICE_READ_KEY = 'NOTICE_1';
