export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "HOME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
        "ROADMAP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roadmap"])},
        "FARMS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Farms"])},
        "tokenFarm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token Farm"])},
        "nftFarm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NFT Farm"])},
        "GAMES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Games"])},
        "MARKETPLACE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marketplace"])},
        "NFTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NFTs"])},
        "MYNFTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My NFTs"])},
        "NFTLibrary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NFT Library"])},
        "ECONOMICS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Economic"])},
        "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["logout"])},
        "OVERALL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overview"])},
        "necoCoin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NECO Token"])},
        "fishCoin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NFISH Token"])}
      },
      "zh": {
        "HOME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["首页"])},
        "ROADMAP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["路线图"])},
        "FARMS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["农场"])},
        "tokenFarm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["代币农场"])},
        "nftFarm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NFT农场"])},
        "GAMES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["游戏"])},
        "MARKETPLACE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NFT市场"])},
        "NFTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NFTs"])},
        "MYNFTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我的NFTs"])},
        "NFTLibrary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NFT图书馆"])},
        "ECONOMICS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["经济模型"])},
        "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["退出"])},
        "OVERALL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["总览"])},
        "necoCoin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NECO母币"])},
        "fishCoin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NFISH子币"])}
      }
    }
  })
}
